import { call, put, select, takeLatest } from "redux-saga/effects";

import { getMoneyByEmail } from "services/money.service";

import { setData, setLoading } from "./money-earned.action";
import { Types } from "./money-earned.constant";
import { paramsSelector } from "./money-earned.selector";

import { pushErrorMessage } from "../snackbar/snackbar.action";

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    // @ts-ignore
    const { email, ...params } = yield select(paramsSelector);

    const { count, data } = yield call(getMoneyByEmail, email, params);

    yield put(setData(count, data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
}
