import React, { lazy, Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";

import "styles/index.scss";

import AppLoading from "components/ui-own/progress/app";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { setUpApi } from "utils/http-interceptors/request";

import "i18n/index";

const App = lazy(() => import("./router"));

function generateUI(): void {
  ReactDOM.render(
    <StrictMode>
      <Suspense fallback={<AppLoading />}>
        <App />
      </Suspense>
    </StrictMode>,
    document.getElementById("root")
  );
}

function createMagic() {
  setUpApi();

  generateUI();
}

// this is a magic
createMagic();

// window.addEventListener("beforeinstallprompt", (e: any) => {
//   // Prevent Chrome 67 and earlier from automatically showing the prompt
//   e.preventDefault();
//
//   // Stash the event so it can be triggered later.
//   let deferredPrompt = e;
//   const addBtn: any = document.getElementById("add-button");
//   // Update UI to notify the user they can add to home screen
//   addBtn.style.display = "block";
//
//   addBtn.addEventListener("click", (e: any) => {
//     // hide our user interface that shows our A2HS button
//     addBtn.style.display = "none";
//     // Show the prompt
//     deferredPrompt.prompt();
//     // Wait for the user to respond to the prompt
//     deferredPrompt.userChoice.then((choiceResult: any) => {
//       if (choiceResult.outcome === "accepted") {
//         console.log("User accepted the A2HS prompt");
//       } else {
//         console.log("User dismissed the A2HS prompt");
//       }
//       deferredPrompt = null;
//     });
//   });
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event: any) => {
        if (event?.target?.state === "activated") {
          alert(
            "There is a new version of the app ready. Please reload to update."
          );
          window.location.reload();
        }
      });

      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
