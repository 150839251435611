export enum KEYBOARD {
  Enter = 13,
}
const APP_ID = "KNA";

export const LANGUAGE = `${APP_ID}_LANGUAGE`;

export enum HTTP_CODE {
  InternalServerError = 500,
  Unauthorized = 401,
}

export enum SNACKBAR_TYPE {
  Error = "error",
  Success = "success",
}

export enum TIMEOUT {
  Default = 300,
  Search = 1000,
  IntervalToken = 15 * 60 * 1000, // second
  RefreshToken = 15, // minute
}

export interface IList {
  count: number;
  data: any[];
  firstLoad: boolean;
  loading: boolean;
}

export const ListState: IList = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
};
