import {
  Types,
  SetUserAction,
  LoginAction,
  SetLoggingAction,
  RegisterNewUserAction,
  SetRegisteringAction,
  LogoutAction,
  SetResettingAction,
  ResetPasswordAction,
  SetChangingAction,
  ChangePasswordAction,
  UpdateProfileAction,
  UpdateUserAction,
} from "./auth.constant";

export function setUser(user?: any): SetUserAction {
  return { type: Types.SET_USER, payload: { user } };
}

export function updateUser(user?: any): UpdateUserAction {
  return { type: Types.UPDATE_USER, payload: { user } };
}

export function setLogging(logging: boolean): SetLoggingAction {
  return { type: Types.SET_LOGGING, payload: { logging } };
}

export function setRegistering(registering: boolean): SetRegisteringAction {
  return { type: Types.SET_REGISTERING, payload: { registering } };
}

export function login(user: any, fnCallback: any): LoginAction {
  return { type: Types.LOGIN, payload: { user, fnCallback } };
}

export function register(user: any, fnCallBack: any): RegisterNewUserAction {
  return { type: Types.REGISTER_NEW_USER, payload: { user, fnCallBack } };
}

export function setResetting(resetting: boolean): SetResettingAction {
  return { type: Types.SET_RESETTING, payload: { resetting } };
}

export function resetPassword(email: string): ResetPasswordAction {
  return { type: Types.RESET_PASSWORD, payload: { email } };
}

export function setChanging(changing: boolean): SetChangingAction {
  return { type: Types.SET_CHANGING, payload: { changing } };
}

export function updateProfile(payload: any): UpdateProfileAction {
  return { type: Types.UPDATE_PROFILE, payload };
}

export function changePassword(payload: any): ChangePasswordAction {
  return { type: Types.CHANGE_PASSWORD, payload };
}

export function logout(): LogoutAction {
  return { type: Types.LOGOUT };
}
