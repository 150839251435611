import { callApi } from "../utils/service.util";

const API_ENDPOINT = "/product/v1";

export const getProducts = async (params?: any) => {
  return callApi({
    method: "get",
    url: API_ENDPOINT,
    params,
  });
};
