import produce from "immer";

import { Actions, IAuth, Types } from "./auth.constant";

import { getUser } from "utils/auth.util";

const user = getUser();

const initialState: IAuth = {
  user,
  hasLogin: !!user,
  changing: false,
  resetting: false,
  logging: false,
  registering: false,
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.SET_USER:
      draft.hasLogin = !!action.payload.user;
      draft.user = action.payload.user;
      draft.logging = false;
      break;
    case Types.UPDATE_USER:
      draft.user = {...draft.user,...action.payload.user};
      break;
    case Types.LOGIN:
      draft.logging = true;
      break;
    case Types.LOGOUT:
      draft.hasLogin = false;
      draft.user = null;
      break;
    case Types.SET_LOGGING:
      draft.logging = action.payload.logging;
      break;
    case Types.REGISTER_NEW_USER:
      draft.registering = true;
      break;
    case Types.SET_REGISTERING:
      draft.registering = action.payload.registering;
      break;
    case Types.RESET_PASSWORD:
      draft.resetting = true;
      break;
    case Types.SET_RESETTING:
      draft.resetting = action.payload.resetting;
      break;
    case Types.UPDATE_PROFILE:
    case Types.CHANGE_PASSWORD:
      draft.changing = true;
      break;
    case Types.SET_CHANGING:
      draft.changing = action.payload.changing;
      break;
  }
}, initialState);

export { initialState, reducer as default };
