import produce from "immer";

import { Actions, IRefer, Types } from "./refer.constant";

const initialState: IRefer = {
  open: false,
  showMessage: false,
  share: false,
  sending: false,
  loading: true,
  multiple: false,
  template: null,
  products: [],
  emails: [],
  tabKey: "PRODUCTS",
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.SET_LOADING:
      draft.loading = false;
      break;
    case Types.SHOW_SHARE:
      draft.open = true;
      draft.loading = true;
      draft.products = action.payload.products || [];
      draft.multiple = action.payload.multiple;
      draft.share = action.payload.share;
      draft.emails = action.payload.emails || [];
      draft.tabKey = "PRODUCTS";
      break;
    case Types.SET_TAB_KEY:
      draft.tabKey = action.payload.tabKey;
      break;
    case Types.LOAD_EMAIL:
      draft.loading = true;
      break;
    case Types.SET_EMAIL_TEMPLATE:
      draft.template = action.payload.template;
      draft.loading = false;
      break;
    case Types.REMOVE_PRODUCT:
      draft.products = draft.products.filter(
        (p: any) => p._id !== action.payload.productId
      );
      break;
    case Types.ADD_PRODUCT:
      if (!action.payload.multiple) {
        draft.products = [action.payload.product];
      } else {
        draft.products.push(action.payload.product);
      }
      break;
    case Types.HIDE_SHARE:
      draft.open = false;
      break;
    case Types.SET_SENDING:
      draft.sending = action.payload.sending;
      break;
    case Types.SEND_MAIL:
      draft.sending = true;
      break;
    case Types.SET_SHOW_MESSAGE:
      draft.sending = false;
      draft.open = false;
      draft.showMessage = action.payload.showMessage;
      break;
    case Types.REMOVE_EMAIL:
      draft.emails = draft.emails.filter(
        (p: any) => p !== action.payload.email
      );
      break;
    case Types.ADD_EMAIL:
      draft.emails.push(action.payload.email);
      break;
  }
}, initialState);

export { initialState, reducer as default };
