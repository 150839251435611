export interface IAuth {
  user: null;
  logging: boolean;
  registering: boolean;
  changing: boolean;
  hasLogin: boolean;
  resetting: boolean;
}

export enum Types {
  SET_USER = "[AUTH] SET_USER",
  UPDATE_USER = "[AUTH] UPDATE_USER",
  LOGOUT = "[AUTH] LOGOUT",
  SET_RESETTING = "[AUTH] SET_RESETTING",
  SET_LOGGING = "[AUTH] SET_LOGGING",
  SET_REGISTERING = "[AUTH] SET_REGISTERING",
  LOGIN = "[AUTH] LOGIN",
  RESET_PASSWORD = "[AUTH] RESET_PASSWORD",
  REGISTER_NEW_USER = "[AUTH] REGISTER_NEW_USER",
  SET_CHANGING = "[AUTH] SET_CHANGING",
  UPDATE_PROFILE = "[AUTH] UPDATE_PROFILE",
  CHANGE_PASSWORD = "[AUTH] CHANGE_PASSWORD",
}

export interface RegisterNewUserAction {
  type: typeof Types.REGISTER_NEW_USER;
  payload: { user: any; fnCallBack: any };
}

export interface SetUserAction {
  type: typeof Types.SET_USER;
  payload: { user: any };
}

export interface UpdateUserAction {
  type: typeof Types.UPDATE_USER;
  payload: { user: any };
}

export interface LoginAction {
  type: typeof Types.LOGIN;
  payload: {
    user: {
      email: string;
      password: string;
    };
    fnCallback: any;
  };
}

export interface SetLoggingAction {
  type: typeof Types.SET_LOGGING;
  payload: {
    logging: boolean;
  };
}

export interface SetRegisteringAction {
  type: typeof Types.SET_REGISTERING;
  payload: {
    registering: boolean;
  };
}

export interface SetResettingAction {
  type: typeof Types.SET_RESETTING;
  payload: {
    resetting: boolean;
  };
}

export interface ResetPasswordAction {
  type: typeof Types.RESET_PASSWORD;
  payload: {
    email: string;
  };
}

export interface SetChangingAction {
  type: typeof Types.SET_CHANGING;
  payload: {
    changing: boolean;
  };
}

export interface UpdateProfileAction {
  type: typeof Types.UPDATE_PROFILE;
  payload: {
    firstName: string;
    lastName: string;
  };
}

export interface ChangePasswordAction {
  type: typeof Types.CHANGE_PASSWORD;
  payload: {
    oldPassword: string;
    password: string;
  };
}

export interface LogoutAction {
  type: typeof Types.LOGOUT;
}

export type Actions =
  | LogoutAction
  | SetLoggingAction
  | SetUserAction
  | UpdateUserAction
  | LoginAction
  | SetChangingAction
  | UpdateProfileAction
  | ChangePasswordAction
  | ResetPasswordAction
  | SetResettingAction
  | SetRegisteringAction
  | RegisterNewUserAction;
