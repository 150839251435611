import { call, put, select, takeLatest } from "redux-saga/effects";
import _size from "lodash/size";

import { getProducts } from "services/product.service";

import { setData, setLoading } from "./product-list.action";
import { Types } from "./product-list.constant";
import { paramsSelector } from "./product-list.selector";

import { pushErrorMessage } from "../snackbar/snackbar.action";

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    const { search, ...params } = yield select(paramsSelector);

    if (_size(search) > 0) {
      params.search = search;
    }

    const { count, data } = yield call(getProducts, params);

    yield put(setData(count, data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
}
