import { IList } from "constants/common";

export interface IProductList extends IList {
  params: {
    page: number;
    limit: number;
    search: string;
  };
}

export enum Types {
  CHANGE_PARAMS = "[PRODUCT LIST] CHANGE PARAMS",
  SET_DATA = "[PRODUCT LIST] SET_DATA",
  LOAD_DATA = "[PRODUCT LIST] LOAD_DATA",
  SET_LOADING = "[PRODUCT LIST] SET_LOADING",
  CLEAR_STATE = "[PRODUCT LIST] CLEAR_STATE",
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    params: any;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any[]; count: number };
}

export type Actions =
  | ClearStateAction
  | SetDataAction
  | LoadDataAction
  | ChangeParamsAction
  | SetLoadingAction;
