import { all, fork } from "redux-saga/effects";

import watchAuth from "./modules/auth/auth.saga";
import watchContactList from "./modules/contact-list/contact-list.saga";
import watchProductList from "./modules/product-list/product-list.saga";
import watchProductDetail from "./modules/product-detail/product-detail.saga";
import watchRefer from "./modules/refer/refer.saga";
import watchDashboard from "./modules/dashboard/dashboard.saga";
import watchMoneyEarned from "./modules/money-earned/money-earned.saga";
import watchActivityList from "./modules/activity-list/activity-list.saga";
import watchCash from "./modules/cash/cash.saga";

export default function* root() {
  yield all([
    fork(watchAuth),
    fork(watchContactList),
    fork(watchProductList),
    fork(watchProductDetail),
    fork(watchRefer),
    fork(watchDashboard),
    fork(watchMoneyEarned),
    fork(watchActivityList),
    fork(watchCash),
  ]);
}
