import { IState } from "store/constants";
import { createSelector } from "reselect";
import { IMoneyEarned } from "./money-earned.constant";

export const moneyEarnedSelector = (state: IState) => {
  return state.moneyEarned;
};

export const paramsSelector = createSelector(
  moneyEarnedSelector,
  (moneyEarned: IMoneyEarned): any => moneyEarned.params
);

export const dataSelector = createSelector(
  moneyEarnedSelector,
  (moneyEarned: IMoneyEarned): any => moneyEarned.data
);

export const loadingSelector = createSelector(
  moneyEarnedSelector,
  (moneyEarned: IMoneyEarned): any => {
    return {
      count: moneyEarned.count,
      firstLoad: moneyEarned.firstLoad,
      loading: moneyEarned.loading,
    };
  }
);
