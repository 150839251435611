import { callApi } from "../utils/service.util";

export const getMoneys = async (params: any) => {
  return callApi({
    method: "get",
    url: `/money/v1`,
    params,
  });
};

export const withdraw = async (data: any) => {
  return callApi({
    method: "post",
    url: `/money/withdraw/v1`,
    data,
  });
};

export const getMoneyByEmail = async (
  email: string,
  params: any = {
    page: 0,
    limit: 3,
  }
) => {
  return callApi({
    method: "get",
    url: `/money/email/v1/${email}`,
    params,
  });
};
