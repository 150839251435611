import axios from "axios";
import _get from "lodash/get";
import { callApi } from "../utils/service.util";

import {
  appleProvider,
  facebookProvider,
  firebase,
  googleProvider,
} from "./firebase.service";

const LOGIN = "/auth/v1";
const RESET_PASSWORD = "/auth/resetPwd/v1";
const RESEND_EMAIL = "/auth/resend-confirm-email/v1";
const REGISTER = "/auth/register/v1";
const UPDATE_PROFILE = "/auth/me/v1";
const CHANGE_PASSWORD = "/auth/updatePwd/v1";

const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // baseURL: "https://kainero-dev-api.azurewebsites.net/api",
  timeout: 30000,
  maxRedirects: 5,
  headers: {
    common: {},
    "Content-Type": "application/json",
  },
});

const handleError = (err: any) => {
  throw new Error(
    _get(err, "response.data.message", "Oops, something went wrong!")
  );
};

export const resendEmail = async (email?: string) => {
  return apiAxios.post(RESEND_EMAIL, { email }).catch(handleError);
};

export const resetPassword = async (email: string) => {
  return apiAxios.put(RESET_PASSWORD, { email }).catch(handleError);
};

export const updateProfile = async (data: any) => {
  return callApi({
    method: "put",
    url: UPDATE_PROFILE,
    data,
  });
};

export const changePassword = async (data: any) => {
  return callApi({
    method: "put",
    url: CHANGE_PASSWORD,
    data,
  });
};

export const loginNotSocial = async (data: any) => {
  return apiAxios.post(LOGIN, data).catch(handleError);
};

export const register = async (data: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}) => {
  return apiAxios.post(REGISTER, data).catch(handleError);
};

export const loginWithGoogle = async () => {
  const userInfo = await firebase.auth().signInWithPopup(googleProvider);

  const profile = userInfo.additionalUserInfo?.profile;
  const idToken = await userInfo.user?.getIdToken();

  return {
    email: _get(profile, "email"),
    firstName: _get(profile, "family_name"),
    lastName: _get(profile, "given_name"),
    picture: _get(profile, "picture"),
    token: idToken,
  };
};

export const loginWithFacebook = async () => {
  const userInfo = await firebase.auth().signInWithPopup(facebookProvider);
  const profile = userInfo.additionalUserInfo?.profile;
  const idToken = await userInfo.user?.getIdToken();

  const email = _get(profile, "email");

  if (email) {
    return {
      email,
      firstName: _get(profile, "first_name"),
      lastName: _get(profile, "last_name"),
      picture: _get(profile, "picture.data.url"),
      token: idToken,
    };
  } else {
    throw new Error("error_facebook_granted_permission_email");
  }
};

export const loginWithApple = async () => {
  const userInfo = await firebase.auth().signInWithPopup(appleProvider);

  const profile = userInfo.additionalUserInfo?.profile;

  const idToken = await userInfo.user?.getIdToken();
  const email = _get(profile, "email");

  let firstName = _get(profile, "first_name");
  let lastName = _get(profile, "last_name");

  if (!firstName || !lastName) {
    const displayName = userInfo.user?.displayName;
    const displayNameComps = displayName?.split(" ") || [];
    firstName = displayNameComps[0];
    lastName = displayNameComps[displayNameComps.length - 1];
  }

  return {
    email,
    firstName: firstName || "",
    lastName: lastName || "",
    picture: null,
    token: idToken,
  };
};
