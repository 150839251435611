import { IState } from "store/constants";
import { createSelector } from "reselect";
import { IProductList } from "./product-list.constant";

export const productListSelector = (state: IState) => {
  return state.productList;
};

export const paramsSelector = createSelector(
  productListSelector,
  (productList: IProductList): any => productList.params
);

export const loadingSelector = createSelector(
  productListSelector,
  (productList: IProductList): any => {
    return {
      loading: productList.loading,
      firstLoad: productList.firstLoad,
    };
  }
);

export const topOffersSelector = createSelector(
  productListSelector,
  (productList: IProductList): any => {
    const data = productList.data.filter((d: any) => d.isTop);
    return data || [];
  }
);

export const allOffersSelector = createSelector(
  productListSelector,
  (productList: IProductList): any => {
    return productList.data || [];
  }
);
