export interface IProductDetail {
  open: boolean;
  data: any;
}

export enum Types {
  OPEN_POPUP = "[PRODUCT DETAIL] OPEN_POPUP",
  HIDE_POPUP = "[PRODUCT DETAIL] HIDE_POPUP",
  CLEAR_STATE = "[PRODUCT DETAIL] CLEAR_STATE",
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export interface OpenPopupAction {
  type: typeof Types.OPEN_POPUP;
  payload: {
    data: any;
  };
}

export interface HidePopupAction {
  type: typeof Types.HIDE_POPUP;
}

export type Actions = OpenPopupAction | HidePopupAction | ClearStateAction;
