import { call, put, select, takeLatest } from "redux-saga/effects";
import _size from "lodash/size";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";

import { addContact, getContacts } from "services/contact.service";

import {
  setAdding,
  setData,
  setLoading,
  clearSearch,
} from "./contact-list.action";
import { AddContactAction, Types } from "./contact-list.constant";
import { Types as TypeRefers } from "../refer/refer.constant";
import { paramsSelector } from "./contact-list.selector";

import {
  pushErrorMessage,
  pushSuccessMessage,
} from "../snackbar/snackbar.action";

export function getFullName(contact: any) {
  if (_isNil(contact) || _isEmpty(contact)) {
    return "";
  }
  const firstName = contact?.givenName || "";
  const lastName = contact?.familyName || "";
  const name = `${firstName.trim()} ${lastName.trim()}`.trim();
  return name;
}

export const normalizeContacts = (contacts: any) => {
  if (!contacts.length) {
    return [];
  }

  contacts.forEach((d: any) => {
    d.title = d.givenName ? d.givenName[0].toUpperCase() : "";
    d.name = getFullName(d);
  });

  return contacts;
};

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    const { search, ...params } = yield select(paramsSelector);

    if (_size(search) > 0) {
      params.search = search;
    }

    const { count, data } = yield call(getContacts, params);

    yield put(setData(count, normalizeContacts(data)));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

function* watchAddData(action: AddContactAction) {
  try {
    const contact = action.payload.contact;

    yield call(addContact, [
      {
        email: contact.email,
        familyName: contact.familyName,
        givenName: contact.givenName,
        phoneNumbers: [
          {
            label: "mobile",
            number: contact.phone,
          },
        ],
      },
    ]);

    yield call(watchLoadData);

    yield put(pushSuccessMessage("success"));

    action.payload.fnCallBack();
  } catch (e) {
    yield put(pushErrorMessage(e));
  }

  yield put(setAdding(false));
}

function* watchRefer() {
  yield put(clearSearch());
  yield call(watchLoadData);
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.ADD_CONTACT, watchAddData);
  yield takeLatest(TypeRefers.HIDE_SHARE, watchRefer);
}
