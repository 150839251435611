import { call, put, takeLatest } from "redux-saga/effects";

import {
  ChangePasswordAction,
  LoginAction,
  RegisterNewUserAction,
  ResetPasswordAction,
  Types, UpdateProfileAction,
} from "./auth.constant";
import {
  setLogging,
  setRegistering,
  setUser,
  updateUser,
  setResetting,
  setChanging,
} from "./auth.action";

import {
  pushErrorMessage,
  pushSuccessMessage,
} from "store/modules/snackbar/snackbar.action";
import {
  loginNotSocial,
  register,
  updateProfile,
  changePassword,
  resetPassword,
} from "services/authentication.service";

import { removeToken, storeToken } from "utils/auth.util";
import _get from "lodash/get";

function* watchLogin(action: LoginAction) {
  try {
    const { data } = yield call(loginNotSocial, action.payload.user);

    yield put(setUser(data.profile));

    storeToken(data.token);
  } catch (e) {
    yield put(setLogging(false));
    if (e.message === "inactivated_user") {
      action.payload.fnCallback();
      return;
    }

    yield put(pushErrorMessage(e));
  }
}

function* watchRegister(action: RegisterNewUserAction) {
  try {
    yield call(register, action.payload.user);

    action.payload.fnCallBack();
  } catch (e) {
    console.log("Nguyen C: auth.saga.ts, F: watchRegister, N: message ", e);
    yield put(pushErrorMessage(e));
  }
  yield put(setRegistering(false));
}

function* watchLogout() {
  removeToken();
}

function* watchResetPassword(action: ResetPasswordAction) {
  try {
    yield call(resetPassword, action.payload.email);

    yield put(pushSuccessMessage("successful_reset_password_message"));
  } catch (e) {
    yield put(pushErrorMessage(e));
  }
  yield put(setResetting(false));
}

function* watchUpdateProfile(action: UpdateProfileAction) {
  try {
    const {firstName, lastName} = yield call(updateProfile, action.payload);

    yield put(updateUser({
      firstName,
      lastName
    }))

    yield put(pushSuccessMessage("profile_successfully_updated"));
  } catch (e) {
    yield put(pushErrorMessage(e));
  }
  yield put(setChanging(false));
}

function* watchChangePassword(action: ChangePasswordAction) {
  try {
    yield call(changePassword, action.payload);

    yield put(pushSuccessMessage("successful_update_password"));
  } catch (e) {
    yield put(pushErrorMessage(e));
  }
  yield put(setChanging(false));
}

export default function* root() {
  yield takeLatest(Types.LOGIN, watchLogin);
  yield takeLatest(Types.LOGOUT, watchLogout);
  yield takeLatest(Types.REGISTER_NEW_USER, watchRegister);
  yield takeLatest(Types.UPDATE_PROFILE, watchUpdateProfile);
  yield takeLatest(Types.CHANGE_PASSWORD, watchChangePassword);
  yield takeLatest(Types.RESET_PASSWORD, watchResetPassword);
}
