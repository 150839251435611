import { IState } from "store/constants";
import { createSelector } from "reselect";
import { IRefer } from "./refer.constant";

import size from "lodash/size";

export const referSelector = (state: IState) => {
  return state.refer;
};

export const openSelector = createSelector(
  referSelector,
  (refer: IRefer): any => {
    return refer.open;
  }
);

export const tabKeySelector = createSelector(
  referSelector,
  (refer: IRefer): any => {
    return refer.tabKey;
  }
);

export const loadingSelector = createSelector(
  referSelector,
  (refer: IRefer): any => {
    return refer.loading;
  }
);

export const emailsSelector = createSelector(
  referSelector,
  (refer: IRefer): any => {
    return refer.emails || [];
  }
);

export const productsSelector = createSelector(
  referSelector,
  (refer: IRefer): any => {
    return refer.products;
  }
);

export const emailsCountSelector = createSelector(
  referSelector,
  (refer: IRefer): any => {
    return size(refer.emails);
  }
);

export const productIdsCountSelector = createSelector(
  referSelector,
  (refer: IRefer): any => {
    return size(refer.products);
  }
);

export const templateSelector = createSelector(
  referSelector,
  (refer: IRefer): any => {
    return refer.template;
  }
);
