import { combineReducers } from "redux";

import auth from "./modules/auth/auth.reducer";
import snackbars from "./modules/snackbar/snackbar.reducer";
import layout from "./modules/layout/layout.reducer";
import contactList from "./modules/contact-list/contact-list.reducer";
import productList from "./modules/product-list/product-list.reducer";
import refer from "./modules/refer/refer.reducer";
import dashboard from "./modules/dashboard/dashboard.reducer";
import productDetail from "./modules/product-detail/product-detail.reducer";
import moneyEarned from "./modules/money-earned/money-earned.reducer";
import activityList from "./modules/activity-list/activity-list.reducer";
import cash from "./modules/cash/cash.reducer";

const common = combineReducers({ snackbars, layout });

const root = {
  auth,
  contactList,
  productList,
  productDetail,
  refer,
  dashboard,
  activityList,
  moneyEarned,
  cash,
  common,
};

export default root;
