import { call, put, select, takeLatest } from "redux-saga/effects";

import { getMoneys } from "services/money.service";

import { setData, setLoading } from "./cash.action";
import { Types } from "./cash.constant";
import { paramsSelector } from "./cash.selector";

import { pushErrorMessage } from "../snackbar/snackbar.action";

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    // @ts-ignore
    const params = yield select(paramsSelector);

    const { count, data, total } = yield call(getMoneys, params);

    yield put(setData(count, data, total));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, [], {}));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
}
