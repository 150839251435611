import {
  AddContactAction,
  ChangeParamsAction, ClearSearchAction,
  ClearStateAction,
  LoadDataAction,
  SetAddingAction,
  SetDataAction,
  SetLoadingAction,
  Types,
} from "./contact-list.constant";

export function setData(count: number, data: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { count, data } };
}

export function loadData(params: any = {}): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { params } };
}

export function changeParams(params: any): ChangeParamsAction {
  return { type: Types.CHANGE_PARAMS, payload: { params } };
}

export function setLoading(loading: boolean): SetLoadingAction {
  return { type: Types.SET_LOADING, payload: { loading } };
}

export function setAdding(adding: boolean): SetAddingAction {
  return { type: Types.SET_ADDING, payload: { adding } };
}

export function addContact(contact: any, fnCallBack: any): AddContactAction {
  return { type: Types.ADD_CONTACT, payload: { contact, fnCallBack } };
}

export function clearState(): ClearStateAction {
  return { type: Types.CLEAR_STATE };
}

export function clearSearch(): ClearSearchAction {
  return { type: Types.CLEAR_SEARCH };
}
