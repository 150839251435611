import axios from "axios";

import _get from "lodash/get";

import { HTTP_CODE } from "constants/common";

import { getToken } from "utils/auth.util";
import { logout } from "store/modules/auth/auth.action";

import store from "../../store";

function formatResponse(response: any): any {
  return response.data;
}

export function handleDataError(error: any) {
  let message;

  if (error.response) {
    if (error.response.status === HTTP_CODE.Unauthorized) {
      if (!window.location.href.includes("login")) {
        store.dispatch(logout());
      }

      message =
        error.response.status === HTTP_CODE.Unauthorized
          ? "Login failed"
          : "Token expired. Please try again!";
    } else if (error.response.status === HTTP_CODE.InternalServerError) {
      message = "Internal Server Error";
    } else {
      message = _get(error.response.data, "message");
    }
  }

  if (!message || message.length < 1) {
    return { message: "Oops, something went wrong" };
  }

  return { message };
}

function handleBeforeCallApi() {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      config.headers.Authorization = getToken();

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
}

function handleAfterCallApi() {
  // Add a response interceptor
  axios.interceptors.response.use(
    function (response: any): any {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return formatResponse(response);
    },
    function (error: any) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(handleDataError(error));
    }
  );
}

function setUpApi() {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  // axios.defaults.baseURL = 'https://kainero-dev-api.azurewebsites.net/api';
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";

  handleBeforeCallApi();

  handleAfterCallApi();
}

export { setUpApi };
