import { IList } from "constants/common";

export interface ICash extends IList {
  total: any;
  params: {
    email: any;
    page: number;
    limit: number;
  };
}

export enum Types {
  CHANGE_PARAMS = "[CASH] CHANGE PARAMS",
  SET_DATA = "[CASH] SET_DATA",
  LOAD_DATA = "[CASH] LOAD_DATA",
  SET_LOADING = "[CASH] SET_LOADING",
  CLEAR_STATE = "[CASH] CLEAR_STATE",
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    params: any;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any[]; count: number; total: any };
}

export type Actions =
  | ClearStateAction
  | SetDataAction
  | LoadDataAction
  | ChangeParamsAction
  | SetLoadingAction;
