import produce from "immer";

import { Actions, IContactList, Types } from "./contact-list.constant";

const initialState: IContactList = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
  adding: false,
  params: {
    page: 0,
    limit: 50,
    search: "",
  },
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.LOAD_DATA:
    case Types.CHANGE_PARAMS:
      draft.loading = true;
      draft.params = { ...draft.params, ...action.payload.params };
      break;
    case Types.SET_LOADING:
      draft.loading = action.payload.loading;
      break;
    case Types.SET_ADDING:
      draft.adding = action.payload.adding;
      break;
    case Types.ADD_CONTACT:
      draft.adding = true;
      break;
    case Types.SET_DATA:
      draft.data = action.payload.data;
      draft.count = action.payload.count;
      draft.loading = false;
      draft.firstLoad = false;
      break;
    case Types.CLEAR_STATE:
      draft.firstLoad = true;
      draft.data = [];
      draft.count = 0;
      draft.data.page = 0;
      break;
    case Types.CLEAR_SEARCH:
      draft.firstLoad = true;
      draft.loading = true;
      draft.params = {
        page: 0,
        limit: 50,
        search: "",
      };
  }
}, initialState);

export { initialState, reducer as default };
