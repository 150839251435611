import { IList } from "constants/common";

export interface IContactList extends IList {
  adding: boolean;
  params: {
    page: number;
    limit: number;
    search: string;
  };
}

export enum Types {
  CHANGE_PARAMS = "[CONTACT LIST] CHANGE PARAMS",
  SET_DATA = "[CONTACT LIST] SET_DATA",
  LOAD_DATA = "[CONTACT LIST] LOAD_DATA",
  SET_LOADING = "[CONTACT LIST] SET_LOADING",
  CLEAR_SEARCH = "[CONTACT LIST] CLEAR_SEARCH",
  CLEAR_STATE = "[CONTACT LIST] CLEAR_STATE",

  ADD_CONTACT = "[CONTACT LIST] ADD_CONTACT",
  SET_ADDING = "[CONTACT LIST] SET_ADDING",
}

export interface ClearSearchAction {
  type: typeof Types.CLEAR_SEARCH;
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export interface SetAddingAction {
  type: typeof Types.SET_ADDING;
  payload: {
    adding: boolean;
  };
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}
export interface AddContactAction {
  type: typeof Types.ADD_CONTACT;
  payload: {
    contact: any;
    fnCallBack: any;
  };
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    params: any;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any[]; count: number };
}

export type Actions =
  | ClearSearchAction
  | ClearStateAction
  | AddContactAction
  | SetAddingAction
  | SetDataAction
  | LoadDataAction
  | ChangeParamsAction
  | SetLoadingAction;
