import { IState } from "store/constants";
import { IAuth } from "store/modules/auth/auth.constant";

import { createSelector } from "reselect";

export const authSelector = (state: IState) => {
  return state.auth;
};

export const hasLoginSelector = createSelector(
  authSelector,
  (auth: IAuth) => !!auth.user
);

export const loggingSelector = createSelector(
  authSelector,
  (auth: IAuth) => auth.logging
);

export const registeringSelector = createSelector(
  authSelector,
  (auth: IAuth) => auth.registering
);

export const resettingSelector = createSelector(
  authSelector,
  (auth: IAuth) => auth.resetting
);

export const userSelector = createSelector(
  authSelector,
  (auth: IAuth): any => auth.user || {}
);

export const changingSelector = createSelector(
  authSelector,
  (auth: IAuth) => auth.changing
);
