import { callApi } from "../utils/service.util";

const API_ENDPOINT = "/template/email/v1";

export const getEmail = async () => {
  return callApi({
    method: "get",
    url: API_ENDPOINT,
  });
};

export const getSMS = async () => {
  return callApi({
    method: "get",
    url: `/template/sms/v1`,
  });
};

export const sendEmailReferral = async (data: any) => {
  return callApi({
    method: "post",
    url: "/email/sendReferral/v1",
    data,
  });
};
