import get from "lodash/get";
import { createSelector } from "reselect";
import { IState } from "store/constants";

import { ICash } from "./cash.constant";

export const cashSelector = (state: IState) => {
  return state.cash;
};

export const paramsSelector = createSelector(
  cashSelector,
  (cash: ICash): any => cash.params
);

export const dataSelector = createSelector(
  cashSelector,
  (cash: ICash): any => cash.data
);

export const loadingSelector = createSelector(
  cashSelector,
  (cash: ICash): any => {
    return {
      count: cash.count,
      firstLoad: cash.firstLoad,
      loading: cash.loading,
    };
  }
);

const getCreditTotal = (total: any) => {
  return total.cash_credit_pending + total.cash_credit + total.exchanged;
};
const getCashTotal = (total: any) => {
  return total.pending + total.withdrawable + total.withdrew;
};
const getTotal = (total: any, type: any) => {
  return type === 1 ? getCreditTotal(total) : getCashTotal(total);
};
const getPending = (total: any, type: any) => {
  return type === 1 ? total.cash_credit_pending : total.pending;
};
const getAvailable = (total: any, type: any) => {
  return type === 1 ? total.cash_credit : total.withdrawable;
};
const getExchangedOrPendingPaidOut = (total: any, type: any) => {
  return type === 1 ? total.exchanged : total.withdrew;
};

export const cashBoardSelector = createSelector(
  cashSelector,

  (cash: ICash): any => {
    const data = cash.total || {};
    const type = get(cash, "params.where.type", 0);

    const total = getTotal(data, type);
    const pending = getPending(data, type);
    const available = getAvailable(data, type);
    const pendingPaidOut = getExchangedOrPendingPaidOut(data, type);

    return {
      total,
      pending,
      available,
      pendingPaidOut,
    };
  }
);
