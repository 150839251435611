import produce from "immer";

import { Actions, IProductDetail, Types } from "./product-detail.constant";

const initialState: IProductDetail = {
  data: [],
  open: false,
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.OPEN_POPUP:
      draft.data = action.payload.data;
      draft.open = true;
      break;
    case Types.CLEAR_STATE:
    case Types.HIDE_POPUP:
      draft.open = false;
  }
}, initialState);

export { initialState, reducer as default };
