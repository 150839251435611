import { callApi } from "../utils/service.util";

const API_ENDPOINT = "/contact/v1";

export const getContacts = async (params?: any) => {
  return callApi({
    method: "get",
    url: API_ENDPOINT,
    params,
  });
};

export const addContact = async (data: any) => {
  return callApi({
    method: "post",
    url: API_ENDPOINT,
    data,
  });
};

export const removeContacts = async (data: any) => {
  return callApi({
    method: "put",
    url: "/contact/remove/v1",
    data,
  });
};

export const getContact = async (id: any) => {
  return callApi({
    method: "get",
    url: `/contact/v1/${id}`,
  });
};
