import i18n from "i18next";

import { initReactI18next } from "react-i18next";
import { getItem } from "utils/localstorage";
import { LANGUAGE } from "constants/common";

const resources = {
  en: { translation: require("./en.json") },
  de: { translation: require("./de.json") },
  fr: { translation: require("./fr.json") },
  br: { translation: require("./br.json") },
  es: { translation: require("./es.json") },
  pt: { translation: require("./pt.json") },
  jp: { translation: require("./jp.json") },
  zh: { translation: require("./zh.json") },
  it: { translation: require("./it.json") },
  ko: { translation: require("./ko.json") },
  dk: { translation: require("./dk.json") },
  no: { translation: require("./no.json") },
  se: { translation: require("./se.json") },
  fi: { translation: require("./fi.json") },
  nl: { translation: require("./nl.json") },
  vi: { translation: require("./vi.json") },
};

const lng = getItem(LANGUAGE);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lng || "en",
    fallbackLng: lng || "en",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
