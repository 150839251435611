import * as _firebase from "firebase/app";
import "firebase/auth";
import { FIREBASE_CONFIG } from "../constants/firebase";

export const googleProvider = new _firebase.auth.GoogleAuthProvider();
googleProvider.addScope("profile");
googleProvider.addScope("email");
googleProvider.setCustomParameters({ prompt: "select_account" });

export const facebookProvider = new _firebase.auth.FacebookAuthProvider();
facebookProvider.addScope("public_profile");

export const appleProvider = new _firebase.auth.OAuthProvider('apple.com')
appleProvider.addScope("email")
appleProvider.addScope("name")

export const firebase = _firebase.initializeApp(FIREBASE_CONFIG);
