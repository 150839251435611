import {
  AddEmailAction,
  AddProductAction,
  HideShareAction,
  LoadEmailAction,
  RemoveEmailAction,
  RemoveProductAction,
  SendEmailAction,
  SendSMSAction,
  SetEmailTemplateAction,
  SetLoadingAction,
  SetSendingAction,
  SetShowMessageAction,
  SetTabKeyAction,
  ShowShareAction,
  Types,
} from "./refer.constant";

export function showShare(payload: any): ShowShareAction {
  return { type: Types.SHOW_SHARE, payload };
}

export function hideShare(): HideShareAction {
  return { type: Types.HIDE_SHARE };
}

export function setTabKey(tabKey: string): SetTabKeyAction {
  return { type: Types.SET_TAB_KEY, payload: { tabKey } };
}

export function addProduct(product: any, multiple: boolean): AddProductAction {
  return { type: Types.ADD_PRODUCT, payload: { product, multiple } };
}

export function removeProduct(productId: string): RemoveProductAction {
  return { type: Types.REMOVE_PRODUCT, payload: { productId } };
}

export function setLoading(loading: boolean): SetLoadingAction {
  return { type: Types.SET_LOADING, payload: { loading } };
}

export function setEmailTemplate(template: any): SetEmailTemplateAction {
  return { type: Types.SET_EMAIL_TEMPLATE, payload: { template } };
}

export function loadEmail(): LoadEmailAction {
  return { type: Types.LOAD_EMAIL };
}

export function setSending(sending: boolean): SetSendingAction {
  return { type: Types.SET_SENDING, payload: { sending } };
}

export function sendEmail(payload: any): SendEmailAction {
  return { type: Types.SEND_MAIL, payload };
}

export function sendSMS(payload: any): SendSMSAction {
  return { type: Types.SEND_SMS, payload };
}

export function setShowMessage(showMessage: boolean): SetShowMessageAction {
  return { type: Types.SET_SHOW_MESSAGE, payload: { showMessage } };
}

export function addEmail(email: string): AddEmailAction {
  return { type: Types.ADD_EMAIL, payload: { email } };
}

export function removeEmail(email: string): RemoveEmailAction {
  return { type: Types.REMOVE_EMAIL, payload: { email } };
}
