import { IState } from "store/constants";
import { createSelector } from "reselect";
import { IActivityList } from "./activity-list.constant";

export const activityListSelector = (state: IState) => {
  return state.activityList;
};

export const paramsSelector = createSelector(
  activityListSelector,
  (activityList: IActivityList): any => activityList.params
);

export const dataSelector = createSelector(
  activityListSelector,
  (activityList: IActivityList): any => activityList.data
);

export const loadingSelector = createSelector(
  activityListSelector,
  (activityList: IActivityList): any => {
    return {
      count: activityList.count,
      firstLoad: activityList.firstLoad,
      loading: activityList.loading,
    };
  }
);
