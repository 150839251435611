export interface IRefer {
  open: boolean;
  tabKey: string;
  showMessage: boolean;
  share: boolean;
  sending: boolean;
  loading: boolean;
  multiple: boolean;
  emails: any;
  products: any;
  template: any;
}

export enum Types {
  SET_LOADING = "[SEND_MAIL] SET_LOADING",
  SET_EMAIL_TEMPLATE = "[SEND_MAIL] SET_EMAIL_TEMPLATE",
  LOAD_EMAIL = "[SEND_MAIL] LOAD_EMAIL",
  SET_TAB_KEY = "[SEND_MAIL] SET_TAB_KEY",
  SHOW_SHARE = "[SEND_MAIL] SHOW_SHARE",
  HIDE_SHARE = "[SEND_MAIL] HIDE_SHARE",
  ADD_PRODUCT = "[SEND_MAIL] ADD_PRODUCT",
  REMOVE_PRODUCT = "[SEND_MAIL] REMOVE_PRODUCT",
  SET_SHOW_MESSAGE = "[SEND_MAIL] SET_SHOW_MESSAGE",
  SEND_MAIL = "[SEND_MAIL] SEND_MAIL",
  SEND_SMS = "[SEND_MAIL] SEND_SMS",
  SET_SENDING = "[SEND_MAIL] SET_SENDING",
  ADD_EMAIL = "[SEND_MAIL] ADD_EMAIL",
  REMOVE_EMAIL = "[SEND_MAIL] REMOVE_EMAIL",
}

export interface SendEmailAction {
  type: typeof Types.SEND_MAIL;
  payload: {
    copy: boolean;
    title: string;
    content: string;
  };
}
export interface SendSMSAction {
  type: typeof Types.SEND_SMS;
  payload: {
    products: any[]
  };
}

export interface SetShowMessageAction {
  type: typeof Types.SET_SHOW_MESSAGE;
  payload: {
    showMessage: boolean;
  };
}

export interface SetEmailTemplateAction {
  type: typeof Types.SET_EMAIL_TEMPLATE;
  payload: {
    template: any;
  };
}

export interface AddProductAction {
  type: typeof Types.ADD_PRODUCT;
  payload: {
    product: any;
    multiple: boolean;
  };
}

export interface RemoveProductAction {
  type: typeof Types.REMOVE_PRODUCT;
  payload: {
    productId: any;
  };
}

export interface AddEmailAction {
  type: typeof Types.ADD_EMAIL;
  payload: {
    email: any;
  };
}

export interface RemoveEmailAction {
  type: typeof Types.REMOVE_EMAIL;
  payload: {
    email: any;
  };
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface SetSendingAction {
  type: typeof Types.SET_SENDING;
  payload: {
    sending: boolean;
  };
}

export interface ShowShareAction {
  type: typeof Types.SHOW_SHARE;
  payload: {
    multiple: boolean;
    share?: boolean;
    products?: any;
    emails?: any;
  };
}

export interface HideShareAction {
  type: typeof Types.HIDE_SHARE;
}

export interface LoadEmailAction {
  type: typeof Types.LOAD_EMAIL;
}

export interface SetTabKeyAction {
  type: typeof Types.SET_TAB_KEY;
  payload: {
    tabKey: string;
  };
}

export type Actions =
  | AddEmailAction
  | RemoveEmailAction
  | SetLoadingAction
  | SendEmailAction
  | SetSendingAction
  | SetEmailTemplateAction
  | ShowShareAction
  | LoadEmailAction
  | HideShareAction
  | SetTabKeyAction
  | SetShowMessageAction
  | AddProductAction
  | RemoveProductAction;
