import {
  ChangeParamsAction,
  ClearStateAction,
  LoadDataAction,
  SetDataAction,
  SetLoadingAction,
  Types,
} from "./cash.constant";

export function setData(count: number, data: any, total: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { count, data, total } };
}

export function loadData(params: any = {}): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { params } };
}

export function changeParams(params: any): ChangeParamsAction {
  return { type: Types.CHANGE_PARAMS, payload: { params } };
}

export function setLoading(loading: boolean): SetLoadingAction {
  return { type: Types.SET_LOADING, payload: { loading } };
}

export function clearState(): ClearStateAction {
  return { type: Types.CLEAR_STATE };
}
