import React from "react";

import styles from "./loading-app.module.scss";

import Logo from "./assets/kainero-logo.png";

const AppLoading = () => {
  return (
    <div className={styles.container}>
      <img alt={"Kainero"} src={Logo} style={{ width: 205, height: 50 }} />
    </div>
  );
};

export default AppLoading;
