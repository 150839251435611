import get from "lodash/get";

import { IState } from "store/constants";

import { createSelector } from "reselect";
import { IDashboard } from "./dashboard.constant";

//Credit
const getCreditTotal = (total: any) => {
  return (
    parseInt(get(total, "cash_credit_pending", 0)) +
    parseInt(get(total, "cash_credit", 0)) +
    parseInt(get(total, "exchanged", 0))
  );
};
//Cash
const getCashTotal = (total: any) => {
  //withdrawable = available
  //withdrew = Pending Paid out

  return (
    parseInt(get(total, "pending", 0)) +
    parseInt(get(total, "withdrawable", 0)) +
    parseInt(get(total, "withdrew", 0))
  );
};
const getTotal = (total: any) => {
  return getCreditTotal(total) + getCashTotal(total);
};

export const dashboardSelector = (state: IState) => {
  return state.dashboard;
};

export const paramsSelector = createSelector(
  dashboardSelector,
  (dashboard: IDashboard): any => dashboard.params
);

export const dataSelector = createSelector(
  dashboardSelector,
  (dashboard: IDashboard): any => dashboard.data
);

export const loadingSelector = createSelector(
  dashboardSelector,
  (dashboard: IDashboard): any => {
    return {
      count: dashboard.count,
      firstLoad: dashboard.firstLoad,
      loading: dashboard.loading,
    };
  }
);

export const mainBoardSelector = createSelector(
  dashboardSelector,
  (dashboard: IDashboard): any => {
    const data = get(dashboard, "total", {});
    const total = getTotal(data);
    const creditTotal = getCreditTotal(data);
    const cashTotal = getCashTotal(data);

    return {
      total,
      creditTotal,
      cashTotal,
    };
  }
);

export const withdrawableSelector = createSelector(
  dashboardSelector,
  (dashboard: IDashboard): any => {
    return get(dashboard, "total.withdrawable", 0);
  }
);
