import { IState } from "store/constants";
import { createSelector } from "reselect";
import { IContactList } from "./contact-list.constant";

export const contactListSelector = (state: IState) => {
  return state.contactList;
};

export const paramsSelector = createSelector(
  contactListSelector,
  (contactList: IContactList): any => contactList.params
);

export const dataSelector = createSelector(
  contactListSelector,
  (contactList: IContactList): any => contactList.data
);


export const loadingSelector = createSelector(
  contactListSelector,
  (contactList: IContactList): any => {
    return {
      count: contactList.count,
      firstLoad: contactList.firstLoad,
      loading: contactList.loading,
    };
  }
);

export const addingSelector = createSelector(
  contactListSelector,
  (contactList: IContactList): any => {
    return contactList.adding;
  }
);
