import moment from "moment";

import {getItem, removeItem, setItem} from "./localstorage";

const USER_DATA = "KNA_USER_DATA";

export const storeToken = (data: any) => {
  setItem(USER_DATA, data);
};

export const removeToken = () => {
  removeItem(USER_DATA);
};

export const getUser = () => {
  try {
    const token: any = getItem(USER_DATA);

    if (token) {
      const user = JSON.parse(atob(token.split(".")[1]));

      const exp: Date = new Date(user.exp * 1000);

      if (exp.getTime() > Date.now()) {
        return user;
      }
    }
  } catch (e) {
    console.log("Nguyen C: localstorage.tsx, F: getUserData, N: e ", e);
    removeItem(USER_DATA);
  }

  return null;
};

export const getToken = () => {
  const token = getItem(USER_DATA);

  return token;
};

export const validCallRefreshToken = () => {
  try {
    const token = getToken();
    if (!token) {
      return false;
    }

    const user = JSON.parse(atob(token.split(".")[1]));

    const now = moment(new Date());
    const end = moment(user.exp * 1000);
    const duration = moment.duration(end.diff(now));
    const minutes = duration.asMinutes();

    return minutes > 0 && minutes < 15;
  } catch (e) {
    console.log("Nguyen C: auth.util.tsx, F: checkExpireDay, N: e ", e);
    return false;
  }
};
