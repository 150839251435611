import { call, put, select, takeLatest } from "redux-saga/effects";
import { getEmail, sendEmailReferral } from "services/email.service";

import {
  hideShare,
  setEmailTemplate,
  setLoading,
  setSending,
  setShowMessage,
} from "./refer.action";
import { referSelector, templateSelector } from "./refer.selector";
import { userSelector } from "../auth/auth.selector";
import { pushErrorMessage } from "../snackbar/snackbar.action";
import { Types } from "./refer.constant";
import { getSMS } from "../../../services/email.service";

const normalizeReferralUrlToHtmlTag = (selectedProducts) => {
  const tags =
    selectedProducts
      ?.map((product) => {
        return `<a href="${product.referralURL}">Referral Link ${product.name}</a>`;
      })
      .join("<br/>") || "";

  return tags;
};

function* watchLoadData() {
  try {
    const template = yield select(templateSelector);
    if (template) {
      yield put(setLoading(false));
      return;
    }

    const data = yield call(getEmail);

    yield put(setEmailTemplate(data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setEmailTemplate({}));
  }
}

function* watchSendEmail(action) {
  try {
    const { emails, products } = yield select(referSelector);

    const sendTo = [...emails];

    if (action.payload.copy) {
      const user = yield select(userSelector);
      sendTo.push(user.email);
    }

    const tags = normalizeReferralUrlToHtmlTag(products);
    const productIDs = products?.map((p) => p?._id);

    const data = {
      to: sendTo,
      payload: {
        title: action.payload.title,
        content: action.payload.content,
        referralURL: tags,
        productIDs,
      },
    };

    yield call(sendEmailReferral, data);

    yield put(setShowMessage(true));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setSending(false));
  }
}

function* watchSendSMS(action) {
  try {
    const { products } = action.payload;

    const { content } = yield call(getSMS);

    if(navigator.share){
        navigator.share({
          title: '',
          text: `
${content}
${products[0].referralURL}
          `.trim(),
          url: products[0].referralURL,
        });
    }

    yield put(hideShare());
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setSending(false));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_EMAIL, watchLoadData);
  yield takeLatest(Types.SEND_MAIL, watchSendEmail);
  yield takeLatest(Types.SEND_SMS, watchSendSMS);
}
