import produce from "immer";

import { Actions, IActivityList, Types } from "./activity-list.constant";

const initialState: IActivityList = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
  params: {
    page: 0,
    limit: 50,
    email: null,
  },
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.LOAD_DATA:
    case Types.CHANGE_PARAMS:
      draft.loading = true;
      draft.params = { ...draft.params, ...action.payload.params };
      break;
    case Types.SET_LOADING:
      draft.loading = action.payload.loading;
      break;
    case Types.SET_DATA:
      draft.data = action.payload.data;
      draft.count = action.payload.count;
      draft.loading = false;
      draft.firstLoad = false;
      break;
    case Types.CLEAR_STATE:
      draft.firstLoad = true;
      draft.data = [];
      draft.count = 0;
      draft.data.page = 0;
      break;
  }
}, initialState);

export { initialState, reducer as default };
